import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import SEO from "../components/seo"
import StaticTextContainer from "../components/StaticTextContainer"

const ShippingReturnsPage = ({ data }) => {
  const { title, body } = data?.shopifyPage
  return (
    <>
      <SEO path="/shipping-returns" title="Jessica Coppet - Shipping and Returns" />
      <StaticTextContainer title={title} body={body} />
    </>
  )
}

ShippingReturnsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query GetShippingReturns {
    shopifyPage(title: {eq: "Shipping and Returns"}) {
      title
      body
    }
  }
`

export default ShippingReturnsPage
